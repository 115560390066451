import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import Header from './shared/header';
import CheckActive from './shared/checkActive';
import UserManagment from './shared/userManagement';
import EventManagment from './shared/eventManager';
import AddUser from './shared/addUser';
import TextField from '@mui/material/TextField';
import { useLocation } from 'react-router-dom';

import CreateBooking from './shared/createBooking';
import MyBookings from './shared/myBookings';
import { getNotice, getTopNotice } from '../network/event';

import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
    GridRowModes,
    DataGrid,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowEditStopReasons,
  } from '@mui/x-data-grid';
  import {
    randomCreatedDate,
    randomTraderName,
    randomId,
    randomArrayItem,
  } from '@mui/x-data-grid-generator';
import { Create } from '@mui/icons-material';

function Book(props) {
    require(`../${props.device}/book.css`);

    const [notice, setNotice] = useState('Loading... \n 123')
    const [topNotice, setTopNotice] = useState('Loading...')

    useEffect(() => {
        getNotice()
        .then(r => {
            setNotice(r)
        })
        getTopNotice()
        .then(r => {
            setTopNotice(r)
        })
    }, [])

    return (<>
        <Header home={'HOME'} css={props.device} ></Header>
        <div className='mainArea'>
        <div className='flex'><h5>LAMBRUK</h5></div>
            <div className='head'>
                <span className='h4fr'>{topNotice}</span>
                <h4>Booking Inquiries</h4>
                <div></div>
            </div>
            <div className='notice'>
                <TextField multiline value={notice} fullWidth>
                    
                </TextField>
            </div>
            <div className='outline b-left'>
                <Accordion defaultExpanded={JSON.parse(true)}>
                    <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    >
                        Create a new booking enquiry
                    </AccordionSummary>
                    <AccordionDetails>
                        {/* create booking goes here */}
                        <CreateBooking />
                    </AccordionDetails>
                </Accordion>

                <Accordion defaultExpanded={JSON.parse(true)}>
                    <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    >
                        My Bookings
                    </AccordionSummary>
                    <AccordionDetails>
                        {/* existing bookings goes here */}
                        <MyBookings />
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    </>)
}

export default Book;