import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import MenuIcon from '@mui/icons-material/Menu';
import EventIcon from '@mui/icons-material/Event';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import GroupIcon from '@mui/icons-material/Group';
import HomeIcon from '@mui/icons-material/Home';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Menu from './shared/menu';

import './eventManagment.css';
import moment from 'moment';
import EventCard from './shared/eventCard';
import { updateStatus, getEvents, setPeriod, checkPeriod, updateServerRow } from '../network/event';
import { breadcrumbsClasses } from '@mui/material';


function EventManagerMobile(props) {
    const [title, setTitle] = React.useState("Event Manager")

    const [events, setEvents] = React.useState([]);

    React.useEffect(() => {
        getEvents()
        .then(r => {
            console.log(r)
            parseEventList(r)
        })
    }, [])

    function updateEvents() {
        return new Promise((resolve, reject) => {
            getEvents()
            .then(r => {
                parseEventList(r)
                resolve(true)
            })
        })
    }

    const calculateNights = (startDate, endDate) => {
        const start = moment(startDate);
        console.log(startDate)
        const end = moment(endDate);
        return end.diff(start, 'days');
    };

    function parseEventList(list) {
        let returnObj = [];
        for (let i = 0; i<list.length; i++) {
            let type;
            if (list[i].extendedProperties.private.type == "1") {
                type = "Weekend"
            } else if (list[i].extendedProperties.private.type == "2") {
                type = "Mid-Week"
            } else {
                type = "Full Week"
            }
            let statusNumber;
            switch (list[i].extendedProperties.private.status) {
                case "Pending":
                    statusNumber = 1;
                    break;
                case "Confirmed Unpaid":
                    statusNumber = 2;
                    break;
                case "Confirmed Paid":
                    statusNumber = 3;
                    break;
                case "Set for deletion":
                    statusNumber = 4;
                    break;
                default:
                    break;
            }
            let room;
            if (`${list[i].extendedProperties.private.rooms}` === `1`) {
                room = "Middle(5 Beds)";
            } else {
                room = "Corner(4 Beds)";
            }
            let payment;
            if (list[i].extendedProperties.private.payment === "true") {
                payment = true;
            } else {
                payment = false;
            }
            console.log(list[i].extendedProperties.private)
            let obj = {
                start: list[i].extendedProperties.private.trueStart.substring(0, 10),
                end: list[i].extendedProperties.private.trueEnd.substring(0, 10),
                room: room,
                beds: `${list[i].extendedProperties.private.beds}`,
                sw: list[i].extendedProperties.private.sw,
                type: type,
                id: list[i].id,
                status: list[i].extendedProperties.private.status,
                statusNumber: statusNumber,
                name: list[i].extendedProperties.private.memberName,
                payment: payment,
                adjust: `${list[i].extendedProperties.private.adjustCost}`,
                nights: calculateNights(list[i].start.dateTime.substring(0, 10), list[i].end.dateTime.substring(0, 10)),
            }
            returnObj.push(obj)
        }
        setEvents(returnObj)
    }

    function EventCards() {
        let arrayElements = [];

        for (let i = 0; i<events.length; i++) {
            arrayElements.push(<EventCard updateEvents={updateEvents} id={events[i].id} adjust={events[i].adjust} start={events[i].start} end={events[i].end} name={events[i].name} beds={events[i].beds} room={events[i].room} type={events[i].type} sw={events[i].sw} nights={events[i].nights} status={events[i].status} statusNumber={events[i].statusNumber} payment={events[i].payment} />)
        }

        return (<>
            {arrayElements}
        </>)
    }
  
    return (
    <div className='mobile-grid'>
      <div className='topbar'>
        <Menu />
        <span className='mobile-title'>{title}</span>
      </div>

      <div className='info-cards'>
            <EventCards />
      </div>
    </div>
    );  
}

export default EventManagerMobile;