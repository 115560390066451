import Header from "./shared/header";
import CheckActive from './shared/checkActive';

function FAQ(props) {
    require(`../${props.device}/faq.css`);

    return (<>
        <CheckActive />
        <Header home={'HOME'} css={props.device}></Header>
        <div className='p'>
                <h3>Lambruk Co-Operative Ltd FAQ's Document</h3>
                <p>
                    To assist with interpretation of Booking Rules:
                </p>
            </div>
            <div className='p'>
                <h3>Q: Lambruk Bookings - Can anyone other than a Member make Lambruk bookings?</h3>
                <p>A: No.</p>
            </div>
            <div className='p'>
                <h3>Q: Associate Bookings - Can a Member book in a non-member?</h3>
                <p>
                    A: (Under Review) A Member is permitted to book in other family members at Members
                    Rates, however, the Member must be present for the duration of the
                    stay.
                    <br/>
                    If the Member finds they cannot be present during the booked stay, the Booking Officer must be advised of the  member’s cancellation prior to the commencement of the booking.
                    <br/>
                    If a Member books in with an Associate and others during the Priority Booking periods,
                    the available booking time to the Member reduces – e.g. 4 weeks becomes 2;
                    2 weeks becomes 1.
                </p>
            </div>
            {/* Truncated for brevity. Add sections for 'Who is an Associate?', 'Maximise Bookings', 'Who is a Substitute Member?', 'How is Ballot Run?' and 'Advising of Cancellations'. */}
            <div className='p'>
                <h3>Note</h3>
                <p>
                    This is meant to be a ‘working document’. Changes will be made as necessary.
                </p>
            </div>
    </>)
}

export default FAQ;