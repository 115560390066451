import Header from './shared/header';
import CheckActive from './shared/checkActive';

function Links(props) {
    require(`../${props.device}/links.css`);

    return (<>
        <CheckActive />
        <Header home={'HOME'} css={props.device} ></Header>
        <div className="vmenu p">
        <br></br>
        <h2>Useful Links</h2>
            
            <br></br>
            <a href='http://www.fallscreek.com.au/' class='vbutton'>http://www.fallscreek.com.au/</a>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <a href='http://www.vicroads.vic.gov.au/Home/' class='vbutton'>http://www.vicroads.vic.gov.au/Home/</a>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <a href='http://www.greatalpinevalleys.com.au/pages/alpine-national-park-camping-hiking/' class='vbutton'>http://www.greatalpinevalleys.com.au/pages/alpine-national-park-camping-hiking/</a>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <a href='http://www.hothambus.com.au/snow-bus_timetables_melbourne_snow.html' class='vbutton'>http://www.hothambus.com.au/snow-bus_timetables_melbourne_snow.html</a>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
        </div>
    </>)
}

export default Links;