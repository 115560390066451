var url = "http://localhost/"
//var url = "http://192.168.0.103/"
//url = "http://192.168.18.239/" // hotspot
url = "https://lambruk.org/"

export function setCookie(name, value, daysToExpire) {
    const d = new Date();
    d.setTime(d.getTime() + (daysToExpire * 24 * 60 * 60 * 1000));
    const expires = "expires=" + d.toUTCString();
    document.cookie = name + "=" + encodeURIComponent(value) + ";" + expires + ";path=/";
}

export function readCookie(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) {
        return decodeURIComponent(c.substring(nameEQ.length, c.length));
      }
    }
    return null;
}

export function updateStatus(id, status) {
    return new Promise((resolve, reject) => {
        fetch(`${url}status/event/?id=${id}&status=${status}`, {
            method: "GET",
            headers: {
                "authorization": readCookie('token')
            },
        })
        .then((response) => response.json())
        .then((data) => {
            // Assuming the data is an array
            //addRows(data);
            //window.location.reload(false);
            
        })
        .catch((error) => {
            console.error(error);
        });
    })
}

export function getEvents() {
    return new Promise((resolve, reject) => {
        fetch(`${url}list/event/`, {
            method: "GET",
            headers: {
                "authorization": readCookie('token')
            },
        })
        .then((response) => response.json())
        .then((data) => {
            resolve(data);
        })
        .catch((error) => {
            console.error(error);
        });
    })
}

export function checkPeriod() {
    return new Promise((resolve, reject) => {
        fetch(`${url}check/period/`, {
            method: "GET",
            headers: {
                "authorization": readCookie('token')
            },
        })
        .then((response) => response.json())
        .then((data) => {
            resolve(data);
        })
        .catch((error) => {
            console.error(error);
        });
    })
}

export function setPeriod(period, type) {
    return new Promise((resolve, reject) => {
        fetch(`${url}set/period/?period=${period}&type=${type}`, {
            method: "GET",
            headers: {
                "authorization": readCookie('token')
            },
        })
        .then((response) => response.json())
        .then((data) => {
            resolve(data);
        })
        .catch((error) => {
            console.error(error);
        });
    })
}

export const fetchMyEvents = async () => {
    try {
      const response = await fetch(`${url}list/event/member/`, {
        method: 'GET',
        headers: {
          Authorization: readCookie("token"),
          'Content-Type': 'application/json', // Adjust the content type as needed
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      //console.log(data)
      return data
    } catch (error) {
      console.error('Error fetching user info:', error);
      //alert(error)
      //setCookie("token", "q10cq0vhrht", 4)
    }
  };

  export const getNotice = async () => {
    try {
      const response = await fetch(`${url}notice/`, {
        method: 'GET',
        headers: {
          Authorization: readCookie("token"),
          'Content-Type': 'application/json', // Adjust the content type as needed
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.text();
      //console.log(data)
      return data
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  export const getTopNotice = async () => {
    try {
      const response = await fetch(`${url}topNotice/`, {
        method: 'GET',
        headers: {
          Authorization: readCookie("token"),
          'Content-Type': 'application/json', // Adjust the content type as needed
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.text();
      //console.log(data)
      return data
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  export function updateNotice(message) {
    const jsonBody = JSON.stringify({message:message});
  
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': readCookie('token')
      },
      body: jsonBody,
    };
  
    return fetch(`${url}notice/update/`, options)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error Status: ${response.status}`);
        }
        return response.json();
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }

  export function updateTopNotice(message) {
    const jsonBody = JSON.stringify({message:message});
  
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': readCookie('token')
      },
      body: jsonBody,
    };
  
    return fetch(`${url}topNotice/update/`, options)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error Status: ${response.status}`);
        }
        return response.json();
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }

  export const createEvent = async (authToken, requestBody) => {
    const urlA = `${url}create/event/`;
  
    const headers = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    });
  
    const options = {
      method: 'POST',
      headers,
      body: JSON.stringify(requestBody),
    };
  
    try {
      const response = await fetch(urlA, options);
      const data = await response.json();
      console.log(data)
      if (data === false) {
        return false
      } else {
        return true
      }


      //window.location.href = '/';
      //const data = await response.json();
      //window.location.href = '/';
      //return data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

export function updateServerRow(eventId, updatedRow) {
    const jsonBody = JSON.stringify(updatedRow);
  
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': readCookie('token')
      },
      body: jsonBody,
    };
  
    return fetch(`${url}update/event/`, options)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error Status: ${response.status}`);
        }
        return response.json();
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
}

export async function getRates(yearsw) {
  try {
    const response = await fetch(`${url}rates/?yearsw=${yearsw}`, {
      method: 'GET',
      headers: {
        Authorization: readCookie("token"),
        'Content-Type': 'application/json', // Adjust the content type as needed
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.text();
    //console.log(data)
    return data
  } catch (error) {
    console.error('Error fetching rates:', error);
  }
}

export async function getAllRates() {
  try {
    const response = await fetch(`${url}rates/all/`, {
      method: 'GET',
      headers: {
        Authorization: readCookie("token"),
        'Content-Type': 'application/json',
         // Adjust the content type as needed
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.text();
    //console.log(data)
    return data
  } catch (error) {
    console.error('Error fetching rates:', error);
  }
}

export async function createNextRate() {
  try {
    const response = await fetch(`${url}rates/next/`, {
      method: 'GET',
      headers: {
        Authorization: readCookie("token"),
        'Content-Type': 'application/json',
         // Adjust the content type as needed
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.text();
    //console.log(data)
    return data
  } catch (error) {
    console.error('Error fetching rates:', error);
  }
}

export function setRates(rates) {
  const jsonBody = JSON.stringify(rates);

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': readCookie('token')
    },
    body: jsonBody,
  };

  return fetch(`${url}rates/set/`, options)
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error Status: ${response.status}`);
      }
      return response.json();
    })
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error);
    });
}