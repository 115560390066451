import HomeMenu from "./shared/homeMenu";
import './book.css';

import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useState } from "react";
import { useNavigate, Link } from 'react-router-dom';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CircularProgress from '@mui/material/CircularProgress';

import { fetchMyEvents, readCookie } from "../network/event";
import EventCard from "./shared/eventCard";
import moment from "moment";
import { setCookie } from "../network/user";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Backdrop from '@mui/material/Backdrop';
import dayjs from "dayjs";

import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { Button } from "@mui/material";
import { getFreeNights } from "../network/user";
import { createEvent } from "../network/event";
import { RedoOutlined } from "@mui/icons-material";


function Book(props) {
    const [freeNightsOptions, setFreeNightsOptions] = useState([0])

    useEffect(() => {
        getFreeNights()
        .then(r => {
            if (r === '2') {
                setFreeNightsOptions([0, 1, 2])
            } else if (r === '1') {
                setFreeNightsOptions([0, 1])
            } else {
                setFreeNightsOptions([0])
            }
            
        })
    }, [])

    const navigate = useNavigate();
    const [value, setValue] = useState(1);

    const [events, setEvents] = React.useState([]);

    const calculateNights = (startDate, endDate) => {
        const start = moment(startDate);
        console.log(startDate)
        const end = moment(endDate);
        return end.diff(start, 'days')+1;
    };

    function EventCards() {
        let arrayElements = [];
        for (let i = 0; i<events.length; i++) {
            arrayElements.push(<EventCard show={false} id={events[i].id} adjust={events[i].adjust} start={events[i].start} end={events[i].end} name={events[i].name} beds={events[i].beds} room={events[i].room} type={events[i].type} sw={events[i].sw} nights={events[i].nights} status={events[i].status} statusNumber={events[i].statusNumber} payment={events[i].payment} />)
        }
        console.log(arrayElements)
        if (events.length == 0) {
            return(<div className="loading-middle">
                <CircularProgress />
            </div>)
        } else if (events[0] == "none") {
            return(<>
                <h3 style={{marginLeft:"5px"}}>No Bookings</h3>
            </>)
        } else {
            return (<>
                {arrayElements}
            </>)
        }
    }

    function convertToMelbourneTime(utcDateString) {
        // Create a Date object from the UTC date string
        const utcDate = new Date(utcDateString);
        
        // Define options for formatting the date
        const options = {
            timeZone: 'Australia/Melbourne', // Set the time zone to Melbourne
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        };
        
        // Convert the UTC date to Melbourne time and format it
        const melbourneDate = utcDate.toLocaleString('en-AU', options);
        //console.warn(melbourneDate)
        return melbourneDate;
    }

    function parseEventList(list) {
        let returnObj = [];
        for (let i = 0; i<list.length; i++) {
            let type;
            if (list[i].extendedProperties.private.type == "1") {
                type = "Weekend"
            } else if (list[i].extendedProperties.private.type == "2") {
                type = "Mid-Week"
            } else {
                type = "Full Week"
            }
            let statusNumber;
            switch (list[i].extendedProperties.private.status) {
                case "Pending":
                    statusNumber = 1;
                    break;
                case "Confirmed Unpaid":
                    statusNumber = 2;
                    break;
                case "Confirmed Paid":
                    statusNumber = 3;
                    break;
                case "Set for deletion":
                    statusNumber = 4;
                    break;
                default:
                    break;
            }
            let room;
            if (`${list[i].extendedProperties.private.rooms}` === `1`) {
                room = "Middle(5 Beds)";
            } else {
                room = "Corner(4 Beds)";
            }
            let payment;
            if (list[i].extendedProperties.private.payment === "true") {
                payment = true;
            } else {
                payment = false;
            }
            console.log(list[i].extendedProperties.private)
            let obj = {
                start: convertToMelbourneTime(list[i].extendedProperties.private.trueStart),
                end: convertToMelbourneTime(list[i].extendedProperties.private.trueEnd),
                room: room,
                beds: `${list[i].extendedProperties.private.beds}`,
                sw: list[i].extendedProperties.private.sw,
                type: type,
                id: list[i].id,
                status: list[i].extendedProperties.private.status,
                statusNumber: statusNumber,
                name: list[i].extendedProperties.private.memberName,
                payment: payment,
                adjust: `${list[i].extendedProperties.private.adjustCost}`,
                nights: calculateNights(list[i].start.dateTime.substring(0, 10), list[i].end.dateTime.substring(0, 10)),
            }
            returnObj.push(obj)
        }
        console.log(returnObj)
        setEvents(returnObj)
    }

    React.useEffect(() => {
        fetchMyEvents()
        .then(r => {
            console.log(r)
            if (r.length == 0) {
                setEvents(["none"])
            } else {
                parseEventList(r)
            }
        })
    }, [])

    // form values
    const [checkIn, setCheckIn] = useState(dayjs(Date.now()));
    const [checkOut, setCheckOut] = useState(dayjs(Date.now()));
    const [season, setSeason] = useState(1)
    const [bookType, setBookType] = useState(2)
    const [room, setRoom] = useState(1)
    const [people, setPeople] = useState(1)
    const [freeNights, setFreeNights] = useState(0)
    const [duration, setDuration] = useState("0 Nights")
    const [notes, setNotes] = useState("")

    const [open, setOpen] = useState(false);

    useEffect(() => {
        let length = calculateNights(checkIn.toDate(), checkOut.toDate())
        if (length == 1) {
            setDuration("1 Night")
        } else {
            setDuration(`${length} Nights`)
        }
    }, [checkIn, checkOut])

    const [done, setDone] = useState(false)

    function Finish() {
        if (done) {
            return
        }
        setDone(true)
        console.log(checkIn)
        console.log(checkOut)
        console.log(season)
        console.log(bookType)
        console.log(room)
        console.log(people)
        console.log(freeNights)
        console.log(notes)

        let tSeason;
        if (season == 1) {
            tSeason = "Winter"
        } else {
            tSeason = "Summer"
        }

        let obj = {
            sw: tSeason,
            beds: people,
            rooms: room,
            type: bookType,
            startDate: checkIn.toISOString(),
            endDate: checkOut.toISOString(),
            freeNights: freeNights,
            notes: notes,
            token: readCookie("token")
        }
        setOpen(true)
        createEvent('123', [
            obj
        ])
        .then(data => {
            setOpen(false)
            if (data === true) {
                setTimeout(() => {
                    window.location.href = '/Book';
                }, 2000)
            } else {
                // error
                alert("Your booking creation had an error.")
                setDone(false)
            }
        })
    }
    //const [check]

    function handleClose() {

    }

    function FreeNightsOptionsElement() {
        return freeNightsOptions.map((option) => <MenuItem onClick={() => {
            setFreeNights(option)
            console.log(freeNights)
        }} value={option}>{option}</MenuItem>)
    }

    return (<div className="mobile-body">
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            onClick={handleClose}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        <div className="mobile-header">
            <span className="mobile-title">LAMBRUK</span>
        </div>
        <div className="mobile-content">
            <h3 style={{marginLeft:"2vw"}}>Book</h3>
            <Accordion>
                <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                >
                Book
            </AccordionSummary>
                <div className="book-mobile">
                    <Typography variant="body1">Check In</Typography>
                    <DatePicker value={checkIn} onChange={(newValue) => setCheckIn(newValue)} />
                    <br></br>
                    <Typography variant="body1">Check Out</Typography>
                    <DatePicker value={checkOut} onChange={(newValue) => setCheckOut(newValue)} />
                    <br></br>
                    <Typography variant="body1">Season</Typography>
                    <FormControl fullWidth>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={season}
                        onChange={(newValue) => setSeason(newValue.target.value)}
                        >
                        <MenuItem value={1}>Winter</MenuItem>
                        <MenuItem value={2}>Summer</MenuItem>
                        </Select>
                    </FormControl>
                    <br></br>
                    <Typography variant="body1">Type</Typography>
                    <FormControl fullWidth>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={bookType}
                        onChange={(newValue) => setBookType(newValue.target.value)}
                        >
                        <MenuItem value={1}>Mid-Week</MenuItem>
                        <MenuItem value={2}>Weekend</MenuItem>
                        <MenuItem value={3}>Full Week</MenuItem>
                        </Select>
                    </FormControl>
                    <br></br>
                    <Typography variant="body1">Room</Typography>
                    <FormControl fullWidth>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={room}
                        onChange={(newValue) => setRoom(newValue.target.value)}
                        >
                        <MenuItem value={1}>Middle(5 Beds)</MenuItem>
                        <MenuItem value={2}>Corner(4 Beds)</MenuItem>
                        </Select>
                    </FormControl>
                    <br></br>
                    <Typography variant="body1">People</Typography>
                    <FormControl fullWidth>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={people}
                        onChange={(newValue) => setPeople(newValue.target.value)}
                        >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        </Select>
                    </FormControl>
                    <br></br>
                    <Typography variant="body1">Claim Free Nights</Typography>
                    <FormControl fullWidth>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={freeNights}
                        onChange={(newValue) => setFreeNights(newValue.target.value)}
                        >
                        <MenuItem value={0}>0</MenuItem>
                        <MenuItem sx={() => {
                            if (setFreeNightsOptions.length > 1) {
                                return {}
                            } else {
                                return {display: 'none'}
                            }
                        }} value={1}>1</MenuItem>
                        <MenuItem sx={() => {
                            if (setFreeNightsOptions.length > 2) {
                                return {}
                            } else {
                                return {display: 'none'}
                            }
                        }} value={2}>2</MenuItem>
                        </Select>
                    </FormControl>
                    <br></br>
                    <Typography onClick={() => {
                        console.log(setFreeNightsOptions)
                    }} variant="body1">Duration</Typography>
                    <TextField
                    InputProps={{
                        readOnly: true,
                    }}
                    value={duration}
                    ></TextField>
                    <br></br>
                    <Typography variant="body1">Notes</Typography>
                    <TextField 
                    value={notes}
                    onChange={(newValue) => setNotes(newValue.target.value)}></TextField>
                    <br></br>
                    <Button onClick={Finish} variant="contained">Submit</Button>
                </div>
            </Accordion>
            <Accordion>
                <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                >
                My Bookings
            </AccordionSummary>
                <EventCards></EventCards>
            </Accordion>
        </div>
        <div className="mobile-nav">
            <Box sx={{ width: "100%", position:"absolute", bottom: "0dvh" }}>
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                    setValue(newValue);
                    }}
                >
                    <BottomNavigationAction onClick={() => {
                        navigate('/');
                    }} label="Home" icon={<HomeIcon />} />
                    <BottomNavigationAction onClick={() => {
                        navigate('/Book');
                    }} label="Book" icon={<AddBoxIcon />} />
                    <BottomNavigationAction onClick={() => {
                        window.location.href='https://calendar.google.com/calendar/u/0/embed?src=4b91b1fa220f3088776462033c6e91cd823736f23750187a1a5def065747024a@group.calendar.google.com&ctz=Australia/Melbourne'
                    }} label="Calendar" icon={<CalendarMonthIcon />} />
                </BottomNavigation>
            </Box>
        </div>
    </div>)
}

export default Book;