import Header from './shared/header';
import { useNavigate, Link } from 'react-router-dom';
import { readCookie } from '../network/user';
import { useEffect } from 'react';
import CheckActive from './shared/checkActive';

function Home(props) {
    require(`../${props.device}/home.css`);

    const navigate = useNavigate();

    useEffect(() => {
        if (readCookie("token") == null) {
            window.location.href = '/login';
        }
        setInterval(() => {
            if (readCookie("token") == null) {
                window.location.href = '/login';
            }
        }, 4000)
    }, [])
    //setCookie("token", "q10cq0vhrht", 1)

    function homeClick() {
        navigate('/');
    }

    function bookClick() {
        navigate('/Book')
    }

    function aboutClick() {
        navigate('/About')
    }

    function contactClick() {
        navigate('/Contact')
    }

    function checkLoggedIn() {
        if (!readCookie('token')) {
            window.location.href = '/Login';
        }
    }

    useEffect(() => {
         checkLoggedIn()
    }, [])

    

    if (!readCookie('token')) {
        return (<></>)
    } else {
        return (<>
            <CheckActive />
            <Header home={'LAMBRUK'} css={props.device} ></Header>
            <div className="mainHomeButtons">
            <button onClick={bookClick}>BOOK</button>
            <button onClick={aboutClick}>ABOUT</button>
            <button onClick={contactClick}>CONTACT US</button>
        </div>
        </>)
    }
}

export default Home;