import MenuIcon from '@mui/icons-material/Menu';
import EventIcon from '@mui/icons-material/Event';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import GroupIcon from '@mui/icons-material/Group';
import HomeIcon from '@mui/icons-material/Home';

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import React from 'react';

function Menu(props) {
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    function goEvent() {
        window.location.href='/mobile/event-management';
    }

    function goUser() {
        window.location.href='/mobile/user-management';
    }

    function goAddUser() {
        window.location.href='/mobile/add-user';
    }

    function goHome() {
        window.location.href='/'
    }
    
    const DrawerList = (
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
          <List>
              <ListItem onClick={goEvent} key={"eventman"} disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                      <EventIcon />
                  </ListItemIcon>
                  <ListItemText sx={{color: "black", fontWeight: "700"}} primary={"Event Management"} />
                  </ListItemButton>
              </ListItem>
              <ListItem onClick={goUser} key={"userman"} disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                      <GroupIcon />
                  </ListItemIcon>
                  <ListItemText sx={{color: "black", fontWeight: "700"}} primary={"User Management"} />
                  </ListItemButton>
              </ListItem>
              <ListItem onClick={goAddUser} key={"adduser"} disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                      <PersonAddAltIcon />
                  </ListItemIcon>
                  <ListItemText sx={{color: "black", fontWeight: "700"}} primary={"Add Users"} />
                  </ListItemButton>
              </ListItem>
          </List>
          <Divider />
          <List>
              <ListItem onClick={goHome} key={"adduser"} disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                      <HomeIcon />
                  </ListItemIcon>
                  <ListItemText sx={{color: "black", fontWeight: "700"}} primary={"Home Page"} />
                  </ListItemButton>
              </ListItem>
          </List>
        </Box>
    );

    const menuButtonSx = {
        marginTop: "10px",
    }

    return (<>
        <div className='menu-button-mobile'>
            <Button sx={menuButtonSx} onClick={toggleDrawer(true)}><MenuIcon /></Button>
            <Drawer open={open} onClose={toggleDrawer(false)}>
            {DrawerList}
            </Drawer>
        </div>
    </>)
}

export default Menu;