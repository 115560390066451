import Card from '@mui/material/Card';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PowerIcon from '@mui/icons-material/Power';
import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import Dialog from '@mui/material/Dialog';

import Button from '@mui/material/Button';

import React from 'react';

import Switch from '@mui/material/Switch';

import '../userManagment.css';

import { GetUsers, activateUser, deactivateUser, updateUsers } from '../../network/user';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function UserCard(props) {
    const buttonSx = {marginTop: "1vh", minWidth: "90%", maxWidth: "90%", marginLeft: "5%", marginBottom: "3vh"};
    const buttonEditSx = {}
    const [active, setActive] = React.useState(props.active);
    const [levyPaid, setLevyPaid] = React.useState(props.levyPaid);

    const [open, setOpen] = React.useState(false);

    const [loading, setLoading] = React.useState(false);

    function startLoading() {
        setLoading(true);
    }
    
    function stopLoading() {
        setLoading(false);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleActiveChange = (event) => {
        setActive(event.target.checked)
    }

    const handleLevyChange = (event) => {
        setLevyPaid(event.target.checked)
    }

    let activeMessage;
    let levyMessage

    if (active) {
        activeMessage = "User is active";
    } else {
        activeMessage = "User is not active"
    }
    if (levyPaid) {
        levyMessage = "Levy has been paid";
    } else {
        levyMessage = "Levy has not been paid";
    }

    function updateUserToServer() {
        let data = [
            {
                email: props.email,
                levyPaid: levyPaid,
                active: active,
                //freeNights: freeNights
            }
        ]
        startLoading()
        updateUsers(data)
        .then(r => {
            props.updateUserList()
            .then(i => {
                stopLoading()
            })
        })
    }



    return (<>

        <Dialog open={open} onClose={handleClose}>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}>
            <CircularProgress color="inherit" />
        </Backdrop>
            <div className='edit-menu'>
            <span className='edit-menu-title'>Edit User</span>
                <div className='flex'>
                    <PersonIcon /><span className='user-title'>{props.name}</span>
                </div>
                <div className='flex'>
                    <Grid3x3Icon /><span className='user-title'>{props.id}</span>
                </div>
                <div className='flex'>
                    <EmailIcon /><span className='user-title'>{props.email}</span>
                </div>
                <div className='flex'>
                    <PowerIcon /><span className='user-title'>{activeMessage}</span>
                </div>
                <div className='flex'>
                    <AttachMoneyIcon /><span className='user-title'>{levyMessage}</span>
                </div>
                <div className='flex'>
                <Switch defaultChecked={active} onChange={handleActiveChange} value={active} /><span className='edit-menu-select-title'>User Active</span>
                </div>
                <div className='flex'>
                <Switch defaultChecked={levyPaid} onChange={handleLevyChange} value={levyPaid} /><span className='edit-menu-select-title'>Levy Paid</span>
                </div>
                <Button onClick={updateUserToServer} variant="contained" sx={buttonEditSx}>Save Changes</Button>
            </div>
        </Dialog>
        <Card>
            <div className='user-card'>
                <div className='flex'>
                    <PersonIcon /><span className='user-title'>{props.name}</span>
                </div>
                <div className='flex'>
                    <Grid3x3Icon /><span className='user-title'>{props.id}</span>
                </div>
                <div className='flex'>
                    <EmailIcon /><span className='user-title'>{props.email}</span>
                </div>
                <div className='flex'>
                    <PowerIcon /><span className='user-title'>{activeMessage}</span>
                </div>
                <div className='flex'>
                    <AttachMoneyIcon /><span className='user-title'>{levyMessage}</span>
                </div>
                <Button onClick={handleClickOpen} variant="contained" sx={buttonSx}>Edit User</Button>
            </div>
        </Card>
    </>)
}

export default UserCard;