import * as React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { createUser } from '../../network/user';

function AddUser(props) {
    const [email, setEmail] = React.useState('')
    const [fullName, setFullName] = React.useState('')
    const [id, setId] = React.useState('')

    let marginLeft = {marginLeft: "10px"}

    function checkFullName() {
        if (fullName.split(' ').length === 2 && fullName.split(' ')[1].length >= 3) {
            return true
        } else {
            return false
        }
    }

    function makeId() {
        if (checkFullName()) {
            let firstName = fullName.split(' ')[0]
            let lastName = fullName.split(' ')[1]
            let id = firstName[0]+lastName[0]+lastName[1]+`00*`
            id = id.toUpperCase()
            setId(id)
            return id
        } else {
            setId('Full name incomplete.')
        }
    }

    function CreateUserReq() {
        props.startLoading()
        createUser({
            email: email,
            name: fullName
        })
        .then(r => {
            if (r === false) {
                console.log('failed')
                alert('Failed creating user')
            } else {
                console.log('successful')
                setFullName('')
                setEmail('')
                props.stopLoading()
            }
        })
    }

    return (<>
        <div className='adduserdiv'>
            <TextField sx={marginLeft}  id="outlined-basic" label="Email Address" variant="outlined" value={email}
                onChange={(event) => {
                    setEmail(event.target.value);
            }} />
            <TextField sx={marginLeft}  id="outlined-basic" label="Full Name" variant="outlined" value={fullName}
                onChange={(event) => {
                    setFullName(event.target.value);
                    makeId()
            }} />
            <Button sx={{width: "20%", marginLeft: "20px"}} variant="contained" onClick={CreateUserReq}>Create</Button>
            {id}
        </div>
    </>)
}

export default AddUser;