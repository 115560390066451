import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

import { useLocation } from 'react-router-dom';
import { useState } from 'react';

import { changePass } from '../network/user';

import "../stylesPc/resetPassword.css";

function ChangePassword(props) {
    const location = useLocation();
  
    const searchParams = new URLSearchParams(location.search);

    const tempToken = searchParams.get('temp');

    const [pass, setPass] = useState('');
    const handlePassChange = (event) => {
        setPass(event.target.value);
    }

    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    const [error, setError] = useState('none')
    const [success, setSuccess] = useState('none')

    const inputSx = {
        width: "100%"
    }
    const buttonSx = {
        width: "100%",
        marginBottom: "15px",
        marginTop: "15px"
    }

    return (<>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        // onClick={handleClose}
      >
      <CircularProgress color="inherit" />
      </Backdrop>
      <div className='reset-password'>
        <h2>Reset your password</h2>
      <TextField sx={inputSx} onChange={handlePassChange} value={pass} id="outlined-basic" label="New Password" variant="outlined" />
      <br></br>
        <Button sx={buttonSx} variant="contained" onClick={() => {
                setOpen(true)
                changePass(pass, tempToken)
                .then(r => {
                    setOpen(false)
                    console.log(r)
                    if (!r) {
                        setError('block')
                        setSuccess('none')
                    } else {
                        setSuccess('block')
                        setError('none')
                        setTimeout(() => {
                            window.location.href = '/login';
                        }, 4000)
                    }
                })
            }}>Change Password</Button>
            <Alert sx={{display: error}} severity="error">Password reset link expired or invalid. Try requesting a password change again.</Alert>
            <Alert sx={{display: success}} severity="success">Password successfully changed. Redirecting to login page.</Alert>
        </div>
    </>)
}

export default ChangePassword;