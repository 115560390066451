import Header from './shared/header';
import CheckActive from './shared/checkActive';
import UserManagment from './shared/userManagement';
import EventManagment from './shared/eventManager';
import AddUser from './shared/addUser';
import RatesTable from './shared/ratesTable';
import Emails from './shared/emails';
import { useLocation } from 'react-router-dom';


import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
    GridRowModes,
    DataGrid,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowEditStopReasons,
  } from '@mui/x-data-grid';
  import {
    randomCreatedDate,
    randomTraderName,
    randomId,
    randomArrayItem,
  } from '@mui/x-data-grid-generator';

  import { getNotice, getTopNotice, updateNotice, updateTopNotice } from '../network/event';

  import Accordion from '@mui/material/Accordion';
  import AccordionDetails from '@mui/material/AccordionDetails';
  import AccordionSummary from '@mui/material/AccordionSummary';
  import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
  import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

  import Backdrop from '@mui/material/Backdrop';
  import CircularProgress from '@mui/material/CircularProgress';
import { TextField } from '@mui/material';

function Management(props) {
    require(`../${props.device}/management.css`);

    const location = useLocation();
  
  // Parse the search string into a URLSearchParams object
  const searchParams = new URLSearchParams(location.search);

  // Access specific query parameters
  const usersOpen = searchParams.get('users');
  const eventsOpen = searchParams.get('events');

  const [noticeText, setNoticeText] = React.useState('')
  const [topNoticeText, setTopNoticeText] = React.useState('')

  const handleNoticeTextChange = (event) => {
    setNoticeText(event.target.value);
  };

  const handleTopNoticeText = event => {
    setTopNoticeText(event.target.value);
  }

  React.useEffect(() => {
    getNotice()
    .then(r => {
        setNoticeText(r)
    })
    getTopNotice()
    .then(r => {
        setTopNoticeText(r)
    })
  }, [])

  function updateNoticeBoard() {
    updateNotice(noticeText)
  }

  const [loading, setLoading] = React.useState(false);
  function startLoading() {
    setLoading(true)
  }
  function stopLoading() {
    setLoading(false)
  }

  function updateTopNoticeText() {
    startLoading()
    updateTopNotice(topNoticeText)
    .then(r => {
        console.log(r)
        stopLoading()
    })
  }

    return (<>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        <Header home={'HOME'} css={props.device} ></Header>
        <div className='main'>
            <div className='flex'><h5>LAMBRUK</h5></div>
            <div className='head'>
                <div className='topnoticeman flex'>
                    <Button onClick={updateTopNoticeText} sx={{width: "50px", marginBottom: "13px", height: "50px", marginRight: "10px"}} variant='contained'>Save</Button>
                    <TextField sx={{marginBottom: "13px", input: { color: "white" } }} value={topNoticeText} onChange={handleTopNoticeText} variant='filled'></TextField>
                </div>
                <h4>User/Event Management Console</h4>
                <h4></h4>
            </div>
            <div className='noticeBoard'>
                <textarea value={noticeText} onChange={handleNoticeTextChange}></textarea>
                <Button onClick={updateNoticeBoard} variant="contained" sx={{}}>Save</Button>
            </div>
            <div className='outline b-left'>
                <Accordion defaultExpanded={JSON.parse(usersOpen)}>
                    <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    >
                        Users
                    </AccordionSummary>
                    <AccordionDetails>
                    {/* datagrid goes here */}
                        <UserManagment startLoading={startLoading} stopLoading={stopLoading}  />
                    </AccordionDetails>
                </Accordion>

                <Accordion defaultExpanded={JSON.parse(eventsOpen)}>
                    <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"  
                    >
                        Bookings
                    </AccordionSummary>
                    <AccordionDetails>
                        <EventManagment startLoading={startLoading} stopLoading={stopLoading}  />
                    </AccordionDetails>
                </Accordion>

                <Accordion defaultExpanded={JSON.parse(eventsOpen)}>
                    <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    >
                        Add User
                    </AccordionSummary>
                    <AccordionDetails>
                    {/* datagrid goes here */}
                        <AddUser startLoading={startLoading} stopLoading={stopLoading}  />
                    </AccordionDetails>
                </Accordion>

                <Accordion defaultExpanded={JSON.parse(eventsOpen)}>
                    <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    >
                        Rates Table
                    </AccordionSummary>
                    <AccordionDetails>
                    {/* datagrid goes here */}
                        <RatesTable startLoading={startLoading} stopLoading={stopLoading} />
                    </AccordionDetails>
                </Accordion>

                {/* <Accordion defaultExpanded={JSON.parse(eventsOpen)}>
                    <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    >
                        Emails
                    </AccordionSummary>
                    <AccordionDetails>
                        <Emails />
                    </AccordionDetails>
                </Accordion> */}
            </div>
        </div>
    </>)
}

export default Management;

