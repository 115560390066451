import { DataGrid } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

import { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { getRowIdFromRowModel } from '@mui/x-data-grid/internals';

import { GetUsers, activateUser, deactivateUser, updateUsers } from '../network/user';

function UserManagment(props) {
    require(`../${props.device}/userManagment.css`);

    function generateRandom() {
        var length = 8,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }

    const columns = [
        { field: 'memberId', headerName: 'memberId', width: 100 },
        { field: 'name', headerName: 'Name', width: 170 },
        { field: 'active', headerName: 'Active', width: 70, editable: true, type: 'boolean' },
        { field: 'email', headerName: 'Email', width: 225 },
        { field: 'levyPaid', headerName: 'Levy Paid', width: 120, editable: true, type: 'boolean' },
        { field: 'freeNights', headerName: 'Free Days', width: 100, editable: true, type: 'number' }
    ]

    const [rows, setRows] = useState([]);
    const [trows, setTrows] = useState([])

    useEffect(() => {
        GetUsers()
        .then(r => {
            setRows(r)
        })
    }, [])

    function Activate() {
        activateUser(trows[0])
    }

    function Deactivate() {
        deactivateUser(trows[0])
    }

    const [finalRows, setFinalRows] = useState([])

    function updateUserToServer() {
        let data = []
        let keys = Object.keys(finalRows)
        for (let i = 0;i<keys.length;i++) {
            let obj = {
                email: finalRows[keys[i]].email,
                levyPaid: finalRows[keys[i]].levyPaid,
                active: finalRows[keys[i]].active,
                freeNights: finalRows[keys[i]].freeNights
            }
            data.push(obj)
        }
        updateUsers(data)
    }

    return (<>
        <div style={{ height: '100vh', width: '65%', backgroundColor: 'white', margin: 0 }}>
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                },
                }}
                pageSizeOptions={[5, 10]}
                // checkboxSelection
                // getRowId={(row) => ({id:generateRandom})}
                sx={{
                    backgroundColor: "none"
                }}
                onCellEditStop={(value) => {
                    console.log("finished change")
                    console.log(value)
                }}
                processRowUpdate={(updatedRow, originalRow) => {
                    setFinalRows(prevRows => {
                        const newRows = { ...prevRows };
                        newRows[updatedRow.id] = updatedRow;
                        return newRows;
                    });
                    return updatedRow;
                }}
                onProcessRowUpdateError={() => {
                    
                }}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setTrows(newRowSelectionModel);
                    console.log(newRowSelectionModel)
                }}
                    rowSelectionModel={trows}
            />
        </div>
        <div className='button-panel'>
        <button className='option green' onClick={updateUserToServer}>Update Users</button>
        {/*
            <button className='option green' onClick={Activate}>Activate</button>
            <button className='option red' onClick={Deactivate}>Deactivate</button>
            <br></br>
            <button className='option green' onClick={Activate}>Levy Paid</button>
            <button className='option red' onClick={Deactivate}>Levy Not Paid</button>
            <br></br>
            <button className='option green' onClick={Activate}></button>
            <button className='option red' onClick={Deactivate}>Levy Not Paid</button>
            */}
        </div>
    </>)
}

export default UserManagment;