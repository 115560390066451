import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useMediaQuery } from 'react-responsive';

// Pages
import Book from './pages/book';
import Contact from './pages/contact';
import FAQ from './pages/faq';
import Home from './pages/home';
import Links from './pages/links';
import Login from './pages/login';
import NewLogin from './pages/loginnew';
import About from './pages/about';
import EventManagment from './pages/eventManagment';
import UserManagment from './pages/userManagment';
import Management from './pages/management';
import ChangePassword from './pages/changePassword';
import ResetPassword from './pages/resetPassword';

import EventManagerMobile from './stylesPhone/eventManagerMobile';
import UserManagerMobile from './stylesPhone/userManagementMobile';
import AddUserMobile from './stylesPhone/addUserMobile';
import HomeMobile from './stylesPhone/home.js';
import BookMobile from './stylesPhone/book.js';
import LoginMobile from './stylesPhone/login.js';

const App = () => {
  const stylesPc = useMediaQuery({ minWidth: 1224 });
  const stylesTablet = useMediaQuery({ minWidth: 768, maxWidth: 1223 });
  const stylesPhone = useMediaQuery({ maxWidth: 767 });
  const stylesPhoneLandscape = useMediaQuery({ maxWidth: 767, orientation: 'landscape' });

  let mobile = false;
  let admin = <Management device={"stylesPc"}/>
  let home = <Home device={"stylesPc"}/>
  let book = <Book device={"stylesPc"}/>
  let signin = <NewLogin device={"stylesPc"}/>

  let device = "stylesPc"
  if (stylesPc) {
    device = "stylesPc"
  } else if (stylesTablet) {
    device = "stylesTablet"
  } else if (stylesPhone) {
    device = "stylesPhone"
    admin = <EventManagerMobile />
    home = <HomeMobile />
    book = <BookMobile />
    signin = <LoginMobile />
  } else if (stylesPhoneLandscape) {
    device = "stylesPhoneLandscape"
  }

  return (<>
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path='/' element={home} />
        <Route path='/book' element={book} />
        <Route path='/contact' element={<Contact device={device}/>} />
        <Route path='/about' element={<About device={device}/>} />
        <Route path='/faq' element={<FAQ device={device}/>} />
        <Route path='/links' element={<Links device={device}/>} />
        <Route path='/login' element={signin} />
        <Route path='/event-management' element={<EventManagment device={device}/>} />
        <Route path='/user-management' element={<UserManagment device={device}/>} />
        <Route path='/admin' element={admin} />
        <Route path='/reset-password' element={<ResetPassword device={device} />} />
        <Route path='/change-password' element={<ChangePassword device={device} />} />
        <Route path='/mobile/event-management' element={<EventManagerMobile />}></Route>
        <Route path='/mobile/user-management' element={<UserManagerMobile />}></Route>
        <Route path='/mobile/add-user' element={<AddUserMobile />}></Route>
      </Routes>
    </BrowserRouter>
    </LocalizationProvider>
    </>
  );
};

export default App;
