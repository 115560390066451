import Header from './shared/header';
import CheckActive from './shared/checkActive';

function Contact(props) {
    require(`../${props.device}/contact.css`);

    return (<>
        <CheckActive />
        <Header home={"HOME"} css={props.device}></Header>
        <div class="containContact">
            <div class='p'>
                <h2>Email</h2>
                <p>example@outlook.com</p>
            </div>
            <div class='p'>
                <h2>Website Host</h2>
                <p>61491652026</p>
            </div>
        </div>
    </>)
}

export default Contact;