var url = "http://localhost/"
//var url = "http://192.168.0.103/"
//url = "http://192.168.18.239/" // hotspot
url = "https://lambruk.org/"

export function setCookie(name, value, daysToExpire) {
    const d = new Date();
    d.setTime(d.getTime() + (daysToExpire * 24 * 60 * 60 * 1000));
    const expires = "expires=" + d.toUTCString();
    document.cookie = name + "=" + encodeURIComponent(value) + ";" + expires + ";path=/";
}

export function readCookie(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) {
        return decodeURIComponent(c.substring(nameEQ.length, c.length));
      }
    }
    return null;
}

export const fetchUserData = async () => {
    try {
    const response = await fetch(`${url}user/info/`, {
        method: 'GET',
        headers: {
        Authorization: readCookie("token"),
        'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    const data = await response.json();
    console.log(data)
    } catch (error) {
    console.error('Error fetching user info:', error);
    }
};

export const getFreeNights = async () => {
    try {
    const response = await fetch(`${url}user/freenights/`, {
        method: 'GET',
        headers: {
        Authorization: readCookie("token"),
        'Content-Type': 'application/json', // Adjust the content type as needed
        },
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    const data = await response.text();
    return data
    console.log(data)
    } catch (error) {
    console.error('Error fetching user info:', error);
    }
};

export function checkActiveRequest() {
    return new Promise((resolve, reject) => {
        const requestUrl = `${url}user/active/`;

        const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': readCookie('token')
        },
        };

        fetch(requestUrl, options)
        .then(response => response.json())
        .then(data => {
            resolve(data)
        })
        .catch(error => {
            console.error('Error:', error);
        });
    })
}

export function RetrieveToken(username, password) {
    return new Promise((resolve, reject) => {
        const RequestUrl = `${url}user/login/?username=${username}&password=${password}`;

        const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        };

        fetch(RequestUrl, options)
        .then(response => response.json())
        .then(data => {
            if (data.token === false) {
                resolve(false)
            } else {
                resolve(data.token)
            }
        })
        .catch(error => {
            // Handle errors
            console.error('Error:', error);
        });
    })
}

export function GetUsers() {
    return new Promise((resolve, reject) => {
        fetch(`${url}users/`, {
            method: 'GET',
            headers: {
                'Authorization': readCookie('token')
            }
        })
        .then(response => response.json())
        .then(data => {
            let temp = []
            for(let i = 0;i<data.length;i++) {
                if (!data[i].periodObject) {
                    data[i].id = data[i].email
                    temp.push(data[i])
                }
            }
            resolve(temp)
        })
        .catch(error => {
            console.error('Error:', error); // Handling any errors
        });
    })
}

export function activateUser(user) {
    try {
        fetch(`${url}user/activate/?id=${user}`, {
            method: 'GET',
            headers: {
                'Authorization': readCookie('token')
            }
        })
        .then(response => response.json())
        .then(data => {
            
        })
        .catch(error => {
            console.error('Error:', error); // Handling any errors
        });
    } catch {}
}

export function deactivateUser(user) {
    try {
        fetch(`${url}user/deactivate/?id=${user}`, {
            method: 'GET',
            headers: {
                'Authorization': readCookie('token')
            }
        })
        .then(response => response.json())
        .then(data => {
            
        })
        .catch(error => {
            console.error('Error:', error); // Handling any errors
        });
    } catch {}
}

export function checkAdminRequest() {
    return new Promise((resolve, reject) => {
        const urlA = `${url}user/admin/`;

        const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': readCookie('token')
        },
        };

        fetch(urlA, options)
        .then(response => response.json())
        .then(data => {
            resolve(data)
        })
        .catch(error => {
            console.error('Error:', error);
        });
    })
}

export function updateUsers(users) {
    const jsonBody = JSON.stringify(users);
  
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': readCookie('token')
      },
      body: jsonBody,
    };
  
    return fetch(`${url}user/update/`, options)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error Status: ${response.status}`);
        }
        return response.json();
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }
  
  export function createUser(info) {
    const jsonBody = JSON.stringify(info);
  
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': readCookie('token')
      },
      body: jsonBody,
    };
  
    return fetch(`${url}user/create/`, options)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error Status: ${response.status}`);
        }
        return response.json();
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }

export function changePass(newPassword, tempToken) {
    const jsonBody = JSON.stringify({new: newPassword});
  
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': tempToken
      },
      body: jsonBody,
    };
  
    return fetch(`${url}user/change/password/`, options)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error Status: ${response.status}`);
        }
        return response.json();
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
}

export function resetPasswordEmail(email) {
    return new Promise((resolve, reject) => {
        const urlA = `${url}user/request/change/password/?email=${email}`;

        const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': readCookie('token')
        },
        };

        fetch(urlA, options)
        .then(response => response.json())
        .then(data => {
            resolve(data)
        })
        .catch(error => {
            console.error('Error:', error);
        });
    })
}