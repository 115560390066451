import { DataGrid } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

import { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { getRowIdFromRowModel } from '@mui/x-data-grid/internals';

import { GetUsers, activateUser, deactivateUser, updateUsers } from '../../network/user';

function UserManagment(props) {
    //require(`../${props.device}/shared.css`);

    function generateRandom() {
        var length = 8,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }

    const columns = [
        { field: 'memberId', headerName: 'memberId', width: 100 },
        { field: 'name', headerName: 'Name', width: 170 },
        { field: 'active', headerName: 'Active', width: 70, editable: true, type: 'boolean' },
        { field: 'email', headerName: 'Email', width: 225, editable: true },
        { field: 'levyPaid', headerName: 'Levy Paid', width: 120, editable: true, type: 'boolean' },
        { field: 'freeNights', headerName: 'Free Nights', width: 100, editable: true, type: 'number' },
        { field: 'notes', headerName: 'Notes', width: 100, editable: true }
    ]

    const [rows, setRows] = useState([]);
    const [trows, setTrows] = useState([])

    useEffect(() => {
        GetUsers()
        .then(r => {
            let newID = []
            for (let i = 0; i<r.length;i++) {
                newID[i] = r[i]
                newID[i].id = r[i]._id;
            }
            setRows(newID)
        })
    }, [])

    const [finalRows, setFinalRows] = useState([])

    function updateUserToServer() {
        let data = []
        let keys = Object.keys(finalRows)
        for (let i = 0;i<keys.length;i++) {
            let obj = {
                email: finalRows[keys[i]].email,
                levyPaid: finalRows[keys[i]].levyPaid,
                active: finalRows[keys[i]].active,
                freeNights: finalRows[keys[i]].freeNights,
                notes: finalRows[keys[i]].notes,
                memberId: finalRows[keys[i]].memberId
            }
            data.push(obj)
        }
        updateUsers(data)
        .then(r => {
            console.log(r)
            setRows({id:'123'})
            props.startLoading()
            GetUsers()
            .then(r => {
                let newID = []
                for (let i = 0; i<r.length;i++) {
                    newID[i] = r[i]
                    newID[i].id = r[i]._id;
                }
                setRows(newID)
                props.stopLoading()
            })
        })
    }

    return (
        <>
            <div className='datagridcontainer'>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                    }}
                    pageSizeOptions={[5, 10]}
                    // checkboxSelection
                    // getRowId={(row) => ({id:generateRandom})}
                    sx={{
                        backgroundColor: "none"
                    }}
                    onCellEditStop={(value) => {
                        console.log("finished change")
                        console.log(value)
                    }}
                    processRowUpdate={(updatedRow, originalRow) => {
                        setFinalRows(prevRows => {
                            const newRows = { ...prevRows };
                            newRows[updatedRow.id] = updatedRow;
                            return newRows;
                        });
                        return updatedRow;
                    }}
                    onProcessRowUpdateError={() => {
                        
                    }}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setTrows(newRowSelectionModel);
                        console.log(newRowSelectionModel)
                    }}
                        rowSelectionModel={trows}
                />
            </div>

            <div className='userbuttons flex'>
                <Button sx={{width: "50%", marginLeft: "10px", marginRight: "10px"}} variant="contained" onClick={updateUserToServer}>Save Changes Made</Button>
            </div>
        </>
    )
}

export default UserManagment;