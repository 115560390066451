import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom'; // 
import { checkAdminRequest } from '../../network/user';
import { setCookie } from '../../network/event';

function Header(props) {
require(`../../${props.css}/home.css`);
  const readCookie = (name) => {
      const cookieValue = document.cookie.replace(
          new RegExp(`(?:(?:^|.*;\\s*)${name}\\s*\\=\\s*([^;]*).*$)|^.*$`),
          '$1'
      );
      return cookieValue || null;
  };

  const [visible, setVisible] = useState('none')

  const navigate = useNavigate();

  function homeClick() {
    navigate('/');
  }

    useEffect(() => {
      checkAdminRequest()
      .then(data => {
        console.log(data)
        if (data.admin === false) {
          console.log("Incorrect")
          setVisible('none')
        } else {
            console.log(data.admin)
            setVisible('block')
        }
      })
    }, [])

    function logout() {
      setCookie('token', '', -1);
      window.location.href = '/login'
    }

    return (
        <div className="flex header" style={{
          marginBottom: 0
        }}>
        <h1 onClick={homeClick}>{props.home}</h1>
        <div className="links">
        <Link to="/Book">Book</Link>
          <Link to="/About">Information Pages</Link>
          <a href='https://calendar.google.com/calendar/u/0/embed?src=4b91b1fa220f3088776462033c6e91cd823736f23750187a1a5def065747024a@group.calendar.google.com&ctz=Australia/Melbourne'>Booking Calendar</a>
          {/* <Link to="/sheets">Booking Sheets</Link> */}
          {/* <Link to="/FAQ">FAQs</Link> */}
          <a style={{color: 'white'}} href="https://84f3700d-678e-481e-b0ce-3679572a5c58.filesusr.com/ugd/987c4a_dec8219c329f44beb8c098409c3ba094.doc?dn=2017%20Non-member%20Kit.doc" target='_blank'>Non-Member Kit</a>
          <Link style={{color: 'white'}} to="/links">Useful Links</Link>
          <Link style={{ display: visible }} to="/admin">Admin Console</Link>
          {/* <Link style={{ display: visible }} to="/admin">User Manager</Link> */}
          <span onClick={logout} className='logout'>Log out</span>
        </div>
      </div>
    )
}

export default Header;