import { DataGrid } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';



import { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { getRowIdFromRowModel } from '@mui/x-data-grid/internals';
import Backdrop from '@mui/material/Backdrop';

import { updateStatus, getEvents, setPeriod, checkPeriod, updateServerRow } from '../network/event';


function EventManagment (props) {
    require(`../${props.device}/eventManagment.css`);

    const [ rows, setRows ] = useState({})

    const columns = [
        { field: 'reference', headerName: 'Reference Number', width: 125 },
        { field: 'name', headerName: 'Name', width: 130 },
        { field: 'memberId', headerName: 'ID', width: 70 },
        { field: 'status', headerName: 'Status', width: 80, editable: true, type: 'singleSelect', valueOptions: ['pending','Confirmed Unpaid', 'Confirmed Paid', 'Set for deletion'] },
        { field: 'startdate', headerName: 'Start Date', width: 100 },
        { field: 'enddate', headerName: 'End Date', width: 100 },
        { field: 'beds', headerName: 'Rooms', width: 70, editable: true, type: 'number' },
        { field: 'type', headerName: 'Type', width: 80, editable: true, type: 'singleSelect', valueOptions: ['Mid-Week', 'Weekend'] },
        { field: 'sw', headerName: 'SW', width: 90, editable: true, type: 'singleSelect', valueOptions: ['Winter', 'Summer'] },
        { field: 'notes', headerName: 'Notes', width: 100, editable: true },
        { field: 'payment', headerName: 'Payment', width: 100, editable: true, type: 'boolean' },
        
    ]

    const [selected, setSelected] = useState(0);
    const [trows, setTrows] = useState([]);
    const [loading, setLoading] = useState(false)

    function startLoading() {
        setLoading(true)
    };

    function stopLoading() {
        setLoading(false)
    };

    function addRows(array) {
        let newObj = []
        for(let i=0;i<array.length;i++) {
            console.log(i)
            if (array[i].summary == undefined) {
                array[i].summary = ''
            }
            let type = array[i].extendedProperties.private.type
            if (type === 1) {
                type = 'Weekend'
            } else {
                type = 'Week'
            }
            let sw = array[i].extendedProperties.private.sw
            if (sw === 1) {
                sw = 'Summer'
            } else {
                sw = 'Winter'
            }
            if (array[i].summary === 'deleted') {
            } else {
            newObj.push({ 
                id: array[i].id,
                reference: array[i].extendedProperties.private.referenceNumber,
                status: capitalizeFirstLetter(array[i].summary),
                memberId: array[i].extendedProperties.private.memberId, 
                startdate: array[i].start.dateTime.substring(0, 10), 
                enddate: array[i].end.dateTime.substring(0, 10), 
                name: array[i].extendedProperties.private.memberName, 
                memberId: array[i].extendedProperties.private.memberId, 
                beds: array[i].extendedProperties.private.beds, 
                type: type, 
                sw: sw, 
                notes: array[i].extendedProperties.private.notes,
                payment: true
            }
                )
            }
        }
        setRows(newObj)
        //setLoading('none')
    }
    function capitalizeFirstLetter(str) {
        //return str.charAt(0).toUpperCase() + str.slice(1);
        return str
    }

    function setToPending(events) {
        events = trows
        for (let i=0;i<events.length;i++) {
            updateStatus(events[i], 'pending')
        }
    }
    function setToConfirmed(events) {
        events = trows
        for (let i=0;i<events.length;i++) {
            updateStatus(events[i], 'confirmed')
        }
    }
    function setToCanceled(events) {
        events = trows
        for (let i=0;i<events.length;i++) {
            updateStatus(events[i], 'canceled')
        }
    }
    function setToDeleted(events) {
        events = trows
        for (let i=0;i<events.length;i++) {
            updateStatus(events[i], 'deleted')
        }
    }

    function setToStrict() {
        setPeriod("strict", "winter")
    }

    function setToNotStrict() {
        setPeriod("notstrict", "winter")
    }

    const [finalRows, setFinalRows] = useState({})

    function saveRowsToServer() {
        let data = { events: [] }
        let keys = Object.keys(finalRows)
        for (let i = 0;i<keys.length;i++) {
            let obj = {
                status: finalRows[keys[i]].status,
                rooms: finalRows[keys[i]].beds,
                sw: finalRows[keys[i]].sw,
                notes: finalRows[keys[i]].notes,
                payment: finalRows[keys[i]].payment,
                eventId: finalRows[keys[i]].id
            };
            data.events.push(obj)
        } 
        updateServerRow('', data);
    }
    

    useEffect(() => {
        getEvents()
        .then(r => {
            addRows(r)
            setLoading('none')
        })
        checkPeriod()
        .then(r => {
            console.log(r)
            setBookingPeriodStatus(`${r[0].period} ${r[0].type}`)
        })
    }, [])
    const [bookingPeriodStatus, setBookingPeriodStatus] = useState('Loading booking period info...')

    return (<>
        <div className='no-overflow'>
                
            <div style={{ height: '100vh', width: '70%', backgroundColor: 'white', margin: 0 }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                    }}
                    pageSizeOptions={[5, 10]}
                    // checkboxSelection
                    // getRowId={(row) => ({id:generateRandom})}
                    sx={{
                        backgroundColor: "none"
                    }}
                    processRowUpdate={(updatedRow, originalRow) => {
                        setFinalRows(prevRows => {
                            const newRows = { ...prevRows };
                            newRows[updatedRow.id] = updatedRow;
                            return newRows;
                        });
                        return updatedRow;
                    }}
                    onProcessRowUpdateError={() => {}}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setTrows(newRowSelectionModel);
                        console.log(newRowSelectionModel)
                    }}
                    rowSelectionModel={trows}
                />
            </div>
        </div>
        <div className='button-panel'>
            <h1 className='selected'>{trows.length} events selected</h1>
            <button className='option' onClick={saveRowsToServer}>Save rows</button>
            {/*
            <button className='option' onClick={setToPending}>Set to pending</button>
            <button className='option' onClick={setToConfirmed}>Set to confirmed</button>
            <button className='option' onClick={setToCanceled}>Set to canceled</button>
            <button className='option' onClick={setToDeleted}>Delete</button>
             */}
            <br></br>
            <h1>Booking Period Status</h1>
            <h1>{bookingPeriodStatus}</h1>
            <button className='option' onClick={setToStrict}>Set to strict</button>
            <button className='option' onClick={setToNotStrict}>Set to non strict</button>
        </div>
    </>)
}

export default EventManagment;