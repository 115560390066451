import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { useState, useEffect } from 'react';

import { RetrieveToken, readCookie, setCookie } from '../network/user.js';

import '../stylesPc/loginnew.css';

import logo from './logo.png';

function NewLogin(props) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [error, setError] = useState('none');

    const [alert, setAlert] = useState({
        visible: 'none',
        text: 'Incorrect Credentials',
        type: 'error',
        title: 'Error'
    })

    function handleEmailInput(event) {
        setEmail(event.target.value)
    }

    function handlePasswordInput(event) {
        setPassword(event.target.value)
    }

    const inputSx = {
        width: "100%",
        marginLeft: "0%"
    }

    const checkBoxSx = {
        width: "100%",
        marginLeft: "-11px",
        marginTop: "-15px",
        paddingLeft: "0"
    }

    const buttonSx = {
        width: "100%",
        height: "4vh"
    }

    const iconSx = {
        width: "8vw",
        height: "8vh",
        marginLeft: "9.5vw",
        marginBottom: "-15px"
    }

    function Signin() {
        RetrieveToken(email, password)
        .then(r => {
            if (r === false) {
                setAlert({
                    visible: 'block',
                    text: 'Incorrect Credentials',
                    type: 'error',
                    title: 'Error'
                })
            } else {
                setCookie('token', r, 999999)
                setAlert({
                    visible: 'block',
                    text: 'Success',
                    type: 'success',
                    title: 'Success'
                })
                window.location.href = '/';
            }
        })
    }

    // Check if user is already signed in, if so redirect to the main page.
    useEffect(() => {
        if (readCookie('token')) {
            window.location.href = '/';
        }
    })

    return (<div className='white'>
        <div className="signin-main">
            <div className='content'>
                {/* <AccountBoxIcon sx={iconSx} /> */}
                <img className='logo-img' src={logo} alt='logo'></img>
                {/* <span className='h1'>LAMBRUK</span> */}
                <span className='title'>Sign in</span>
                <TextField value={email} onChange={handleEmailInput} sx={inputSx} label='Email Address' type='email' id='email' variant='outlined'></TextField>
                <TextField value={password} onChange={handlePasswordInput} sx={inputSx} label='Password' type='password' id='password' variant='outlined'></TextField>
                <FormControlLabel sx={checkBoxSx} control={<Checkbox defaultChecked />} label="Remember me" />
                <Alert severity="error" sx={{ display: error }}>Incorrect Username or Password</Alert>
                <Button onClick={Signin} variant="contained">Sign in</Button>
                <Alert sx={{
                marginTop: "30px",
                marginBottom: "30px",
                width: "100%",
                paddingRight: 0,
                marginLeft: "0%",
                display: alert.visible
            }} severity={alert.type}>
                <div className='links1'>
                    <a href='/reset-password' className='link1 floatLeft'>Forgot Password?</a>
                </div>
                <AlertTitle>{alert.title}</AlertTitle>
                {alert.text}
                </Alert>
            </div>
        </div>
    </div>)
}

export default NewLogin;