import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

import { useLocation } from 'react-router-dom';
import { useState } from 'react';

import { resetPasswordEmail } from '../network/user';

import '../stylesPc/resetPassword.css';

function ChangePassword(props) {
    const location = useLocation();
  
    const searchParams = new URLSearchParams(location.search);

    const tempToken = searchParams.get('temp');

    const [pass, setPass] = useState('');
    const handlePassChange = (event) => {
        setPass(event.target.value);
    }

    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    const [error, setError] = useState('none')
    const [success, setSuccess] = useState('none')

    const buttonSx = {
        width: "100%",
        paddingTop: "15px",
        paddingBottom: "15px"
    }
    const inputSx = {
        width: "100%",
    }

    return (<>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        // onClick={handleClose}
      >
      <CircularProgress color="inherit" />
      </Backdrop>
        <div className='reset-password'>
        <h2>Request a password change</h2>
        <TextField sx={inputSx} onChange={handlePassChange} value={pass} id="outlined-basic" label="Email Address" variant="outlined" />
            <br></br>
            <br></br>
            <Button sx={buttonSx} variant="contained" onClick={() => {
                setOpen(true)
                resetPasswordEmail(pass)
                .then(r => {
                    setSuccess('block')
                    setOpen(false)
                    setPass('')
                })
            }}>Send password change email</Button>
        </div>
    </>)
}

export default ChangePassword;