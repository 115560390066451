import { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { checkActiveRequest } from '../../network/user';

function CheckActive() {
    const [open, setOpen] = useState(false)

    useEffect(() => {
        const interval = setInterval(checkActiveReq, 5000);
    
        //return () => clearInterval(interval);
      }, []);

      useEffect(() => {
        checkActiveReq();
      }, [])

    function checkActiveReq() {
        checkActiveRequest()
        .then(data => {
          //console.log(data)
            if (data.active === false) {
                //console.log("Incorrect")
                setOpen(true)
            } else {
                //console.log(data.active)
                setOpen(false)
            }
        })
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        color: "black"
    };

    return (<>
        <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          Please contact the Treasurer regarding your account status.
        </Box>
      </Modal>
    </>)
}

export default CheckActive