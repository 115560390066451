import { GetUsers, activateUser, deactivateUser, updateUsers } from '../network/user';

import './userManagementMobile';

import UserCard from './shared/userCard';

import * as React from 'react';
import Menu from './shared/menu';


function UserManager(props) {
    const [users, setUsers] = React.useState([]);
    const [elements, setElements] = React.useState();

    React.useEffect(() => {
        GetUsers()
        .then(r => {
            setUsers(r)
        })
    }, []);

    function UserCards(props) {
        let list = props.users
        let users = [];
        for (let i = 0; i<list.length; i++) {
            users.push(<UserCard updateUserList={updateUserList} name={list[i].name} id={list[i].memberId} email={list[i].email} active={list[i].active} levyPaid={list[i].levyPaid}  />)
        }

        return users;
    };

    function updateUserList() {
        return new Promise((resolve, reject) => {
            GetUsers()
            .then(r => {
                setUsers(r)
                resolve(true)
            })
        })
    };

    return (<>
        <div className='mobile-grid'>
        <div className='topbar'>
            <Menu />
        <span className='mobile-title'>User Management</span>
      </div>

      <div className='info-cards'>
        <UserCards users={users}/>
      </div>
        </div>
    </>)
}

export default UserManager;