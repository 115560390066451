import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';

import { useEffect, useState } from 'react';
import moment from 'moment-timezone';

import { DataGrid } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';

import { readCookie } from '../../network/user';
import { createEvent, fetchMyEvents } from '../../network/event';
//import CheckActive from './shared/checkActive';

moment.tz.setDefault("Australia/Melbourne");

function MyBookings(props) {
    const columns = [
        { field: 'reference', headerName: 'Reference Number', width: 125 },
        // { field: 'name', headerName: 'Name', width: 130 },
        { field: 'sw', headerName: 'Season', width: 90 },
        { field: 'type', headerName: 'Type', width: 80 },
        { field: 'startdate', headerName: 'Check In', width: 100 },
        { field: 'enddate', headerName: 'Check Out', width: 100 },
        { field: 'rooms', headerName: 'Room', width: 120 },
        { field: 'beds', headerName: 'People', width: 70 },
        { field: 'nights', headerName: 'Nights', width: 60 },
        { field: 'status', headerName: 'Status', width: 80 },
        { field: 'notes', headerName: 'Notes', width: 500 }
    ]
    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState('block')
    const [trows, setTrows] = useState([])

    function calculateEventDuration(startDate, endDate) {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const duration = Math.abs(end - start) / (1000 * 60 * 60 * 24);
        return duration;
    }

    const calculateNights = (startDate, endDate) => {
        const start = moment(startDate);
        console.log(startDate)
        const end = moment(endDate);
        return end.diff(start, 'days');
    };

    function convertToMelbourneTime(utcDateString) {
        // Create a Date object from the UTC date string
        const utcDate = new Date(utcDateString);
        
        // Define options for formatting the date
        const options = {
            timeZone: 'Australia/Melbourne', // Set the time zone to Melbourne
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        };
        
        // Convert the UTC date to Melbourne time and format it
        const melbourneDate = utcDate.toLocaleString('en-AU', options);
        console.warn(melbourneDate)
        return melbourneDate;
    }

    function addRows(array) {
        let newObj = []
        for(let i=0;i<array.length;i++) {
            //console.log(array[i])
            let type = array[i].extendedProperties.private.type
            if (type === "1") {
                type = 'Weekend'
            } else if (type === "2") {
                type = 'Mid-Week'
            } else {
                type = 'Full Week'
            }
            let sw = array[i].extendedProperties.private.sw
            if (sw === "1") {
                sw = 'Summer'
            } else {
                sw = 'Winter'
            }
            let room;
            if (array[i].extendedProperties.private.rooms === '1') {
                room = 'Middle(4 Beds)'
            } else {
                room = 'Corner(5 Beds)'
            }
            if (array[i].summary === 'deleted') {
            } else {
            newObj.push({ 
                id: array[i].id,
                reference: array[i].extendedProperties.private.referenceNumber,
                status: array[i].extendedProperties.private.status,
                memberId: array[i].extendedProperties.private.memberId, 
                // startdate: array[i].start.dateTime.substring(0, 10), 
                // enddate: array[i].end.dateTime.substring(0, 10), 
                // startdate: array[i].extendedProperties.private.trueStart.substring(0, 10), 
                // enddate: array[i].extendedProperties.private.trueEnd.substring(0, 10),
                startdate: convertToMelbourneTime(array[i].extendedProperties.private.trueStart),
                enddate: convertToMelbourneTime(array[i].extendedProperties.private.trueEnd),
                name: array[i].extendedProperties.private.memberName, 
                memberId: array[i].extendedProperties.private.memberId,
                //nights: calculateNights(array[i].start.dateTime.substring(0, 10), array[i].end.dateTime.substring(0, 10)),
                nights: calculateNights(array[i].extendedProperties.private.trueStart.substring(0, 10), array[i].extendedProperties.private.trueEnd.substring(0, 10)),
                beds: array[i].extendedProperties.private.beds,
                rooms: room,
                type: type, 
                sw: sw, 
                notes: array[i].extendedProperties.private.notes
            })
            }
            //console.log(newObj[i])
        }
        //console.log(newObj)
        setRows(newObj)
        setLoading('none')
    }

    const [summerWinter, setSummerWinter] = useState(1)
    const [beds, setBeds] = useState(1)
    const [weekendWeek, setWeekendWeek] = useState(2)
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [notes, setNotes] = useState('')

    const handleSummerWinterChange = (event) => {
        setSummerWinter(event.target.value)
    }
    const handleBedsChange = (event) => {
        setBeds(event.target.value)
    }
    const handleWeekendWeekChange = (event) => {
        setWeekendWeek(event.target.value)
    }
    const handleStartDateChange = (event) => {
        setStartDate(event)
    }
    const handleEndDateChange = (event) => {
        setEndDate(event)
    }
    const handleNotesChange = (event) => {
        setNotes(event.target.value)
    }

    useEffect(() => {
        fetchMyEvents()
        .then(r => {
            addRows(r)
        })
    }, [])

    function generateRandomString() {
        const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let randomString = '';
    
        for (let i = 0; i < 8; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            randomString += characters.charAt(randomIndex);
        }
    
        return randomString;
    }

    return (<>
        <div className='CreateBooking'>
        <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                    }}
                    pageSizeOptions={[5, 10]}
                    // checkboxSelection
                    // getRowId={(row) => ({id:generateRandom})}
                    sx={{
                        backgroundColor: "white",
                    }}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setTrows(newRowSelectionModel);
                        //console.log(newRowSelectionModel)
                    }}
                      rowSelectionModel={trows}
                />
        </div>
    </>)
}

export default MyBookings;