import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';

import moment from 'moment-timezone';

import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';

import { readCookie, getFreeNights } from '../../network/user';
import { createEvent, fetchMyEvents } from '../../network/event';
import { parsePath } from 'react-router-dom';
//import CheckActive from './shared/checkActive';

function CreateBooking(props) {
    useEffect(() => {
        getFreeNights()
        .then(r => {
            if (r === '2') {
                setFreeNights(['0', '1', '2'])
            } else if (r === '1') {
                setFreeNights(['0', '1'])
            } else {
                setFreeNights(['0'])
            }
        })
    }, [])

    const [freeNights, setFreeNights] = useState(['0'])

    const [duration, setDuration] = useState(0);

    const calculateNights = (startDate, endDate) => {
        const start = moment(startDate);
        console.log(startDate)
        const end = moment(endDate);
        return end.diff(start, 'days');
    };

    var newColumns = [
        { field: 'startdate', headerName: 'Check In', type: 'date', editable: true, flex: 0.05 },
        { field: 'enddate', headerName: 'Check Out', type: 'date', editable: true, flex: 0.05 },
        { field: 'sw', headerName: 'Season',  type: 'singleSelect', valueOptions: ['Summer', 'Winter'], editable: true, flex: 0.05 },
        { field: 'type', headerName: 'Type', type: 'singleSelect', valueOptions: ['Mid-Week', 'Weekend', 'Full Week'], editable: true, flex: 0.05 },
        { field: 'rooms', headerName: 'Room', type: 'singleSelect', valueOptions: ['Middle(5 Beds)', 'Corner(4 Beds)'], editable: true, flex: 0.05 },
        { field: 'beds', headerName: 'People', type: 'singleSelect', valueOptions: [1, 2, 3, 4, 5], editable: true, flex: 0.05 },
        { field: 'freeNights', headerName: 'Claim Free Nights', type: 'singleSelect', valueOptions: freeNights, editable: true, flex: 0.075 },
        { field: 'nights', headerName: 'Duration(Nights)', flex: 0.05 },
        { field: 'notes', headerName: 'Notes', editable: true, flex: 0.3 }
    ]

    const [error, setError] = useState(false)

    function Finish() {
        let startDate = newFinalRows.startdate
        startDate.setHours(16);
        //startDate = new Date(startDate.getTime() + 24 * 60 * 60 * 1000);
        let endDate = newFinalRows.enddate;
        endDate.setHours(16);
        //endDate = new Date(endDate.getTime() + 24 * 60 * 60 * 1000);
        let obj = {
            sw: newFinalRows.sw,
            beds: newFinalRows.beds,
            rooms: newFinalRows.rooms,
            type: newFinalRows.type,
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
            freeNights: newFinalRows.freeNights,
            notes: newFinalRows.notes,
            memberId: 123,
            token: readCookie("token"),
        }

        if (obj.type === "Weekend") {
            obj.type = 1
        } else if (obj.type === "Mid-Week") {
            obj.type = 2
        } else {
            obj.type = 3
        }
        if (obj.rooms === 'Middle(5 Beds)') {
            obj.rooms = 1;
        } else {
            obj.rooms = 2
        }

        console.log(obj)
        createEvent('123', [
            obj
        ])
        .then(data => {
            if (data === true) {
                setTimeout(() => {
                    window.location.href = '/';
                }, 2000)
            } else {
                handleOpen()
            }
        })
    }
    let test = 0;
    const [nRows, setNrows] = useState([{id:generateRandomString(), sw: 'Winter', type: 'Weekend', startdate: new Date(), enddate: new Date(), beds: 'Select', notes: '', freeNights: "0", rooms: 'Select', nights: 0}]);
    const [newRows, setNewRows] = useState([])
    const [newFinalRows, setNewFinalRows] = useState({})
    useEffect(() => {
        console.warn('test')
    }, nRows)

    function generateRandomString() {
        const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let randomString = '';
    
        for (let i = 0; i < 8; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            randomString += characters.charAt(randomIndex);
        }
    
        return randomString;
    }

    const handleClose = () => {
        setError(false);
      };
      const handleOpen = () => {
        setError(true);
      };

    return (<>
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={error}
        onClick={handleClose}
      >
        <p>An error occured while making your booking.</p>
      </Backdrop>
        <div className='CreateBooking'>
            <DataGrid
                rows={nRows}
                columns={newColumns}
                initialState={{
                pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                },
                }}
                pageSizeOptions={[5, 10]}
                // checkboxSelection
                // getRowId={(row) => ({id:generateRandom})}
                sx={{
                    backgroundColor: "white",
                }}
                processRowUpdate={(updatedRow, originalRow) => {
                    setNewFinalRows(prevRows => {
                        let newRows = { ...prevRows };
                        // newRows[0].nights = test;
                        // test++;
                        
                        let testRow = updatedRow
                        newRows[updatedRow.id] = testRow;
                        console.log(testRow)
                        let nightsDuration = calculateNights(testRow.startdate, testRow.enddate)
                        testRow.nights = nightsDuration;
                        return newRows;
                    });
                    setNewFinalRows(updatedRow)
                    return updatedRow;
                }}
                onProcessRowUpdateError={() => {}}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    //console.warn(nRows)
                    setNewRows(newRowSelectionModel);
                }}
                rowSelectionModel={newRows}
            />
        </div>
        <div className='buttonsArea'>
            <Button variant="contained" onClick={Finish}>Submit Booking Enquiry</Button>
        </div>
    </>)
}

export default CreateBooking;