import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Dialog from '@mui/material/Dialog';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { updateStatus, getEvents, setPeriod, checkPeriod, updateServerRow } from '../../network/event';

import React, { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function EventCard(props) {
    const cardSx = {
        padding: "10px"
    }

    const [open, setOpen] = React.useState(false);

    const [loading, setLoading] = React.useState(false);

    function startLoading() {
        setLoading(true);
    }
    
    function stopLoading() {
        setLoading(false);
    }

    const [status, setStatus] = React.useState(props.statusNumber);
    const [beds, setBeds] = React.useState(parseInt(props.beds));
    const [room, setRoom] = React.useState(parseInt(props.room));
    const [payment, setPayment] = React.useState(props.payment);

    const handlePaymentChange = (event) => {
        setPayment(event.target.checked);
        console.log(payment)
    }

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    }

    const handleBedsChange = (event) => {
        setBeds(event.target.value);
    }

    const handleRoomChange = (event) => {
        setRoom(event.target.value);
    }

    const handleClickOpen = () => {
        console.log(payment)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function saveChange() {
        let id = props.id;

    }

    function saveRowsToServer() {
        let oStatus;
        switch (status) {
            case 1:
                oStatus = "Pending";
                break;
            case 2:
                oStatus = "Confirmed Unpaid";
                break;
            case 3:
                oStatus = "Confirmed Paid";
                break;
            case 4:
                oStatus = "Set for deletion";
                break;
            default:
                break;
        }
        let data = {
            events: [
                {
                    status: oStatus,
                    rooms: room,
                    beds: beds,
                    payment: `${payment}`,
                    eventId: props.id,
                    adjust: `${props.adjust}`,
                }
            ]
        }
        startLoading();
        updateServerRow('', data)
        .then(r => {
            props.updateEvents()
            .then(r => {
                stopLoading()
            })
        })
    }

    const [show, setShow] = useState("block");
    if (props.show === false && show !== "none") {
        setShow("none")
    }

    return (<>
        <Dialog open={open} onClose={handleClose}>
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
            <div className='edit-menu'>
                <div className='grid'>
                <span className='edit-menu-title'>Edit Event</span>
                    <div className='date-info'>
                        <div className='flex'>
                            <span className='date-bubble'>
                                {props.start}
                            </span>
                            <span className='date-to'>
                                <ArrowForwardIcon sx={{marginTop: "5px", marginRight: "5px"}} />
                            </span>
                            <span className='date-bubble'>
                                {props.end}
                            </span>
                        </div>
                    
                    </div>
                    
                        <span className='edit-menu-select'>Status</span>
                        <Select value={status} onChange={handleStatusChange} defaultValue={props.statusNumber}>
                            <MenuItem value={1}>Pending</MenuItem>
                            <MenuItem value={2}>Confirmed Unpaid</MenuItem>
                            <MenuItem value={3}>Confimed Paid</MenuItem>
                            <MenuItem value={4}>Set for deletion</MenuItem>
                        </Select>
                        {/* <span className='edit-menu-select'>Beds</span>
                        <Select value={beds} onChange={handleBedsChange} defaultValue={parseInt(props.beds)}>
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                        </Select>
                        <span className='edit-menu-select'>Room</span>
                        <Select value={room} onChange={handleRoomChange} defaultValue={parseInt(props.room)}>
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                        </Select> */}
                        <span className='edit-menu-select'>Payment {payment}</span>
                        <div className='flex'>
                        <Checkbox checked={payment} onChange={handlePaymentChange} /><span>Payment</span>
                        </div>
                        <span className='edit-menu-select-info'>Duration: {props.nights} nights</span>
                        <span className='edit-menu-select-info'>During {props.sw}</span>
                        <span className='edit-menu-select-info'>Type: {props.type}</span>
                        <span className='edit-menu-select-info'>Member: {props.name}</span>
                        <Button onClick={saveRowsToServer} sx={{marginTop: "1vh", minWidth: "90%", maxWidth: "90%", marginLeft: "5%", marginBottom: "3vh"}} variant="contained">Save Changes</Button>
                </div>
            </div>
        </Dialog>
        <Card sx={cardSx}>
            <div className='event'>
                <div className='flex'>
                    <CalendarMonthIcon /><span className='event-title'>{props.name}</span>
                </div>
                <div className='date-info'>
                    <div className='flex'>
                        <span className='date-bubble'>
                            {props.start}
                        </span>
                        <span className='date-to'>
                            <ArrowForwardIcon sx={{marginTop: "5px", marginRight: "5px"}} />
                        </span>
                        <span className='date-bubble'>
                            {props.end}
                        </span>
                    </div>
                </div>
                    <div className='flex-justify-center'>
                        <span className='other-bubble'>
                         {props.beds} Beds
                        </span>
                        <span className='other-bubble'>
                            Room {props.room}
                        </span>
                    </div>
                    <div className='flex-justify-center'>
                        <span className='other-bubble'>
                            {props.sw}
                        </span>
                        <span className='other-bubble'>
                            {props.type}
                        </span>
                    </div>
                    <div className='flex-justify-center'>
                        <span className='other-bubble'>
                            {props.status}
                        </span>
                        <span className='other-bubble'>
                            {props.nights} Nights
                        </span>
                    </div>
                    <Button onClick={handleClickOpen} sx={{marginTop: "1vh", minWidth: "90%", maxWidth: "90%", marginLeft: "5%", marginBottom: "3vh", display: show}} variant="contained">Edit</Button>
                </div>
        </Card>
    </>)
}

export default EventCard;