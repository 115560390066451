import Menu from './shared/menu';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import './addUser.css';
import { createUser } from '../network/user';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import React from 'react';

function AddUserMobile(props) {
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    
    const [loading, setLoading] = React.useState(false);

    function startLoading() {
        setLoading(true)
    };

    function stopLoading() {
        setLoading(false)
    };

    const handleNameChange = (event) => {
        setName(event.target.value)
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value)
    };

    function createUserButton() {
        startLoading();
        createUser({
            email: email,
            name: name
        })
        .then(r => {
            if (r === false) {
                console.log('failed')
                stopLoading();
                alert('Failed creating user/Name or email already in use')
            } else {
                console.log('successful')
                setName('');
                setEmail('');
                stopLoading();
            }
        })
    }

    return (<>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <div className='mobile-grid'>
            <div className='topbar'>
                <Menu />
            <span className='mobile-title'>Add Users</span>
        </div>

        <div className='info-cards'>
            <TextField value={name} onChange={handleNameChange} id="outlined-basic" label="Member Full Name" variant="outlined" />
            <TextField value={email} onChange={handleEmailChange} id="outlined-basic" label="Member Email" variant="outlined" />
            <Button onClick={createUserButton} variant='contained'>Create User</Button>
        </div>
        </div>
    </>)
}

export default AddUserMobile;